import React, { createContext, useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';

export const SiteContext = createContext();

  export const SiteContextProvider = ({ children }) => {
  const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PRO;;
  const [userData, setUserData] = useState(null);
  const [siteconfig, setSiteconfig] = useState({});
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [orderTotal, setOrderTotal] = useState(null);
  const [userToken, setUserToken] = useState(null)
  const [isLoggedOn, setIsLoggedOn] = useState(false);
  const [msg, setMsg] = useState('');
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const singleProduct = true;


  useEffect(() => {
    getUserDetails();
    getSiteConfig();
    getJobsCat()
  },[]);


    const getJobsCat = () => {
      fetch(`${apiUrl}/api/jobcategory.php?action=fetch`, {
        method: 'get'
      })
        .then((res) => res.json())
        .then((res) => {
  
          if(res.status === 200) {
            setCategories(res.rows);
            }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    }
  
const getSiteConfig = () => {
    fetch(`${apiUrl}/api/siteupdate.php?action=fetchsiteconfig`, {
      method: 'get'
    })
    .then((res) => {
      return res.json()
    })
    .then(async (response) => {
      setSiteconfig(response.rows);
    })
    .catch((err) => {
      console.log(err);
    })
}


const slugify = (string) => {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}


  const getUserDetails = async () => {
    const fromStorageUser = await localStorage.getItem('user');
    const parsedVUser = JSON.parse(fromStorageUser);
    if(fromStorageUser !== null) {
       await setUserData(parsedVUser);
        await setIsLoggedOn(true);
    }
  };


const handleSignOut = async () => {
    await setUserData(null);
    await setIsLoggedOn(false);
    await localStorage.removeItem('user');
    await localStorage.removeItem('loggedin', false);
}

const handleSignIn = async (data) => {
    const storedUser = JSON.stringify(data);

    await setUserData(data);
    await setIsLoggedOn(true);
    await localStorage.setItem('user', storedUser);
    await localStorage.setItem('loggedin', true);
}


  const contextValue = {
    apiUrl,
    items,
    categories,
    msg,
    isLoggedOn,
    userData,
    userToken,
    siteconfig,
    isMobile,
    slugify,
    setItems,
    handleSignOut,
    handleSignIn,
    setUserData,
    getJobsCat
  };

  return (
    <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>
  );
};
