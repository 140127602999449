import React, {useState, useEffect, useContext} from 'react';
import { NavigationBar } from '../components';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { SiteContext } from '../context';

const Blog = () => {

  let {id} = useParams();

const { apiUrl } = useContext(SiteContext);

const [article, setArticle] = useState({});

useEffect(() => {
    doViewsUpdate();
    window.scrollTo(0,0);
},[id])

const doViewsUpdate = () => {
    fetch(`${apiUrl}/api/articles.php?action=viewsupdate&id=${id}`, {
        method:'get'
      }).then((res) => {
          return res.json()
      }).then((res) => {
        if(res.status === 200) {
            setArticle(res.article);
          } else if(res.status === 400) {
            console.log(res.msg)
          }
      }).catch((err) => {
          console.log(err)
      })    
}
    return (
        <div>
            <div className='container my-2 p-3'>
                    <NavigationBar section={'Blog'} page={article.titleslug} goback={true} />


              <div className='p-2'>
              {article.hasOwnProperty('artid') ? <div className='my-1 py-2 rounded'>
                  <h3 className='py-2'>{article.title}</h3>
                  <div className='small text-left py-2'> <i className='bi bi-person-circle'></i> {article.author} | <i className='bi bi-clock'></i>: {moment(article.createdAt).format('Do MMM YYYY h:mma')} | <i className='bi bi-eye'></i> {article.views}</div>

              <img className='col-md-6' src={`${apiUrl}/${article.artphoto}`} height={250} width='80%' alt="article" /><br />
              <div className='small text-left py-2'> Caption: {article.artphotocaption}</div>
            
              <div className='py-2' dangerouslySetInnerHTML={{__html: article.fullcontent }} />

            </div>: <div className='my-2'>Article {id} not found</div>}
            </div>

            </div>
 
        </div>
    )
}

export default Blog;