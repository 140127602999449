import React, {useState, useEffect, useContext} from 'react';
import {Header, Footer} from './';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { DashboardMenu, NavigationBar } from '../components';
import { SiteContext } from '../context';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const validationSchema = Yup.object().shape({
    pagetitle: Yup.string()
      .label('Title')
      .required()
      .min(3, 'Must have at least 3 characters'),
    pagecat: Yup.string()
      .label('Category')
      .required(),
  });

const SitePageUpdate = () => {

    let {sitepage} = useLocation().state; 
    let navigate = useNavigate();

const { apiUrl, userData, slugify} = useContext(SiteContext);

const [story, setStory] = useState('');
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

useEffect(() => {
  setStory(sitepage.cpagecontent);
},[])

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if(story === '' || story === undefined) {
      alert('Please provide the content for the article.');
      setSubmitting(false);
      return;
    }

    let formData = new FormData();
  
    formData.append('pageid', values.pageid);
    formData.append('pagecat', values.pagecat);
    formData.append('pagetitle', values.pagetitle);
    formData.append('pagetitleslug', slugify(values.pagetitle));
    formData.append('pagestory', story);
    formData.append('photo', values.photo);
    formData.append('pageusername', userData.username);
    formData.append('role', userData.role);
    formData.append('action', 'update');
  
      return fetch(`${apiUrl}/api/pages.php`, {
        method: 'post',
        body: formData
      })
          .then((res) => res.json())
        .then(async (res) => {
  
          console.log(res);
  
          if(res.status === 200) {
              resetForm({});
              setSubmitting(false);
              setMsg(res.msg);
              setTimeout(() => {
                setMsg('');
              }, 3000);
              setTimeout(() => {
                navigate('/adminpages');
            }, 3000);
          } else if(res.status === 400) {
            setErrorMsg(res.msg);
            setTimeout(() => {
              setErrorMsg('');
            }, 5000);
            setSubmitting(false);
          } else {
            alert(
                  'Page submission failed',
                  'Error connection to the server please check your details and try again',
                  [{ text: 'Okay' }]
                );
          }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    };


    return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>

                    <NavigationBar section={'Pages'} goback={true} />

                <h3>Page Manage</h3>
                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                  </div>
                </div>

                <Formik
                    initialValues={{
                      pageid: sitepage.cpageid != '' ? sitepage.cpageid : '',
                      pagecat: sitepage.cpagemenu != '' ? sitepage.cpagemenu : '',
                      pagetitle: sitepage.cpagename != '' ? sitepage.cpagename : '',
                      photo: sitepage.cpagephoto != '' ? sitepage.cpagephoto : '',
                      pageusername: sitepage.cpage_postedby != '' ? sitepage.cpage_postedby : userData.username,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='row my-2'>
                          <div className='col-md-8'>

                          <div className='form-group my-1'>
                          <div>
                            <label htmlFor="pagecat">Category</label>
                          </div>
                            <Field
                              as="select"
                              id="pagecat"
                              name="pagecat"
                              value={values.pagecat}
                              placeholder="Category"
                              onBlur={handleBlur('pagecat')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            >
                              <option value="">Select...</option>
                              <option value="About">About</option>
                              <option value="Services">Services</option>
                              <option value="Clients">Clients</option>
                              <option value="General">General</option>
                            </Field>

                          {touched.pagecat && errors.pagecat && <div className='py-1'>
                                <span className='text-danger'>{touched.pagecat && errors.pagecat}</span>
                          </div>}
                        </div>

                            <div className='form-group my-2'>
                            <div>
                              <label htmlFor="pagetitle">Title</label>
                            </div>
                            <Field
                              id="pagetitle"
                              name="pagetitle"
                              value={values.pagetitle}
                              placeholder="Title"
                              onBlur={handleBlur('pagetitle')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.pagetitle && errors.pagetitle && <div className='py-1'>
                                  <span className='text-danger'>{touched.pagetitle && errors.pagetitle}</span>
                            </div>}
                          </div>


                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="pagestory">Content</label>
                          </div>
                          <ReactQuill theme="snow" value={story} onChange={setStory}/>
                        </div>

                          </div>
                          <div className='col-md-4'>
                          <div className='form-group my-3'>
                                <div>
                                    <label htmlFor="photo"> Upload picture</label>
                                </div>
                                <input id="photo" name="photo" type="file" onChange={(event) => {
                                        setFieldValue("photo", event.currentTarget.files[0]);
                                }} />
                                                        {sitepage && <img className='my-2' src={`${apiUrl}/${sitepage.cpagephoto}`} height={100} width={150} alt="picture" />}
                        </div>


                          </div>
                        </div>
                        <button
                            type='submit'
                            onClick={handleSubmit}
                            title="Submit"
                            disabled={!isValid || isSubmitting}
                            className='btn btn-primary'
                          >
                              Submit
                          </button>
                        
                        </form>
                    )}
                  </Formik>
                              </div>
                    </div>
                </div>
            </div>
          <Footer />
        </div>
    )
}

export default SitePageUpdate;