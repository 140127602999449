import React, {useState, useEffect, useContext} from 'react';
import {Header, Footer} from './';
import { Pagination } from '../components';
import moment from 'moment';
import { Link, useNavigate, useLocation, Outlet } from 'react-router-dom';
import { SiteContext } from '../context';

const BlogLists = () => {

const { apiUrl } = useContext(SiteContext);

const [latestArticles, setLatestArticles] = useState([]);
const [total, setTotal] = useState(null);
const [limit, setLimit] = useState(5);
const [page, setPage] = useState(1);
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

useEffect(() => {
  getArticles();
},[page,limit])

const getArticles = () => {
  fetch(`${apiUrl}/api/articles.php?action=fetchblog&page=${page}&limit=${limit}`, {
    method:'get'
  }).then((res) => {
      return res.json()
  }).then((res) => {
    if(res.status === 200) {
      if(res.rows.length > 0) {
        setLatestArticles(res.popular);    
    }
      } else if (res.status === 400) {
          setMsg(res.msg)
          setTimeout(() => {
            setMsg('')
          },3000)
      }
  }).catch((err) => {
      console.log(err)
  })
}

    return (
        <div>
            <Header />
            <div className='container my-1 p-1'>

                <div className='row'>
                    <div className='col-md-8'>
                        <Outlet />
                    </div>
                    <div className='col-md-4 bg-light mt-2'>
                        <h4 className='bg-dark text-white rounded border-bottom p-2'>Most Read</h4>
                        {latestArticles.length > 0 && latestArticles.map((latestarticle,i) => {
                            return (
                            <div key={i} className='text-decoration-none border-bottom p-3 my-2'>
                              <Link className='text-primary text-decoration-none' to={`/blog/post/${latestarticle.titleslug}`} >
                                <div style={{'height':60, 'overflow':'hidden'}} className='float-start'><img className='img-fluid align-self-center mx-2 pr-1' src={`${apiUrl}/${latestarticle.artphoto}`} height={80} width={80} alt="article" /></div>
                                <div>{latestarticle.title}<br />
                                    <span className=' font-italic small'> <i className='bi bi-user'></i>{latestarticle.author} | <i className='bi bi-clock'></i>: {moment(latestarticle.createdAt).format('Do MMM YYYY h:mma')}</span>
                                </div>
                              </Link>
                            </div>  
                            )
                         })}

                    </div>
                </div>
            </div>
          <Footer />
        </div>
    )
}

export default BlogLists;