import React, {useState, useEffect, useContext} from 'react';
import {Header, Footer} from './';
import { useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { DashboardMenu, NavigationBar } from '../components';
import { SiteContext } from '../context';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    cat: Yup.string()
      .label('Category')
      .required()
  });

const PhotoGalleryCatAdd = () => {

    let navigate = useNavigate();

const { apiUrl, slugify } = useContext(SiteContext);

const userData = JSON.parse(localStorage.getItem('user'));

const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

  const handleSubmit = (values, { setSubmitting, resetForm }) => {

    let formData = new FormData();

    formData.append('cat', values.cat);
    formData.append('role', userData.role);
    formData.append('action', 'createcat');
  
      return fetch(`${apiUrl}/api/photogallery.php`, {
        method: 'post',
        body: formData
      })
          .then((res) => res.json())
        .then(async (res) => {

          console.log(res);
  
          if(res.status === 200) {
              resetForm({});
              setSubmitting(false);
              setMsg(res.msg);
              setTimeout(() => {
                setMsg('');
              }, 3000);
              setTimeout(() => {
                navigate('/adminphotogallerycat');
            }, 3000);
          } else if(res.status === 400) {
            setErrorMsg(res.msg);
            setTimeout(() => {
              setErrorMsg('');
            }, 5000);
            setSubmitting(false);
          } else {
            alert(
                  'Photo category submission failed',
                  'Error connection to the server please check your details and try again',
                  [{ text: 'Okay' }]
                );
          }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    };
    


    return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>

                    <NavigationBar section={'Photo Gallery'} goback={true} />

                <h3>Photo Gallery Category Add</h3>
                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                  </div>
                </div>

                <Formik
                    initialValues={{
                      cat: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <div>
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='container my-2'>

                          <div className='form-group my-1'>
                          <div>
                            <label htmlFor="cat">Category</label>
                          </div>
                            <Field
                              id="cat"
                              name="cat"
                              value={values.cat}
                              placeholder="Category"
                              onBlur={handleBlur('cat')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />

                          {touched.cat && errors.cat && <div className='py-1'>
                                <span className='text-danger'>{touched.cat && errors.cat}</span>
                          </div>}
                        </div>


                        </div>

                        <span className='px-2'>
                        <button
                            type='submit'
                            onClick={handleSubmit}
                            title="Submit"
                            disabled={!isValid || isSubmitting}
                            className='btn btn-primary'
                          >
                              Submit
                          </button>
                          </span>
                        
                        <span className='px-2'>
                          <button
                            type='submit'
                            onClick={() => navigate(-1)}
                            title="Submit"
                            disabled={!isValid || isSubmitting}
                            className='btn btn-secondary'
                          >
                              Cancel
                          </button>
                        </span>
                        
                        </form>
                        </div>
                    )}
                  </Formik>
                              </div>
                    </div>
                </div>
            </div>
          <Footer />
        </div>
    )
}

export default PhotoGalleryCatAdd;