import React, { useState, useEffect, useContext } from 'react';
import {Header, Footer, ProjectGallery} from './';
import { Link } from 'react-router-dom';
import { HomeProducts, HomeCategories, NewsLetterBox } from '../components';
import { SiteContext } from '../context/index';
import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion'

const Projects = () => {

    return (
        <div>
            <Header />

            <ProjectGallery />

            <Footer />

        </div>
    )
}

export default Projects;