import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { Link } from "react-router-dom";
import { HomeProducts, HomeCategories, NewsLetterBox } from "../components";
import { SiteContext } from "../context/index";
import Carousel from "react-bootstrap/Carousel";
import Accordion from "react-bootstrap/Accordion";

const Home = () => {
  const { userData, apiUrl, isLoggedOn, categories, isMobile } =
    useContext(SiteContext);

  const [stories, setStories] = useState([]);
  const [clientLogos, setClientLogos] = useState([]);

  useEffect(() => {
    getArticles();
    getClientLogos();
  }, []);

  const getArticles = () => {
    fetch(`${apiUrl}/api/articles.php?action=fetchblog&page=1&limit=4`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setStories(res.rows);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClientLogos = () => {
    fetch(`${apiUrl}/api/pages.php?action=fetchpagelogos`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setClientLogos(res.rows);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Header />

      <Carousel>
        <Carousel.Item interval={1000}>
          <img
            style={{ "object-fit": "cover" }}
            className="d-block w-100"
            src="../BBCCDED2-614A-42B3-B145-E55798AF010D.jpeg"
            height={600}
            alt="First slide"
          />
          <div
            className="mt-0 p-5 rounded"
            style={{
              padding: "5% !important",
              color: "#fff",
              position: "absolute",
              backgroundColor: "#333",
              opacity: 0.7,
              left: isMobile ? "5%" : "10%",
              bottom: isMobile ? "10%" : "40%",
              width: isMobile ? "90%" : "40%",
            }}
          >
            <h1 className="text-warning">Welcome to Safety2000</h1>
            <p className="h4">
              Mechanical Engineering, Construction, Oil & Gas Field
              Developments.
            </p>
          </div>
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={500}>
          <img
            style={{ "object-fit": "cover" }}
            className="d-block w-100"
            src="../FE355FD0-330E-4CF4-81D4-FAA94BDD9AC7.jpeg"
            height={600}
            alt="Second slide"
          />
          <div
            className="mt-0 p-5 rounded"
            style={{
              padding: "5% !important",
              color: "#fff",
              position: "absolute",
              backgroundColor: "#333",
              opacity: 0.7,
              left: isMobile ? "5%" : "10%",
              bottom: isMobile ? "10%" : "40%",
              width: isMobile ? "90%" : "40%",
            }}
          >
            <h1 className="text-warning">We Connect You</h1>
            <p className="h4">To the Oil and Gas World.</p>
          </div>
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ "object-fit": "cover" }}
            className="d-block w-100"
            src="../93C37094-C393-4747-A527-8564C9F24FBC.jpeg"
            height={600}
            alt="Third slide"
          />
          <div
            className="mt-0 p-5 rounded"
            style={{
              padding: "5% !important",
              color: "#fff",
              position: "absolute",
              backgroundColor: "#333",
              opacity: 0.7,
              left: isMobile ? "5%" : "10%",
              bottom: isMobile ? "10%" : "40%",
              width: isMobile ? "90%" : "40%",
            }}
          >
            <h1 className="text-warning">Our Core Values</h1>
            <p className="h4">Integrity | Reliability | Accountability</p>
          </div>
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div className="bg-light">
        <div className="container text-center p-5">
          <div className="my-2 py-2 h2">SAFETY 2000 LIMITED</div>
          <p>
            For many years, Safety 2000 Limited has been a respected consulting
            and engineering partner to industrial clients, private investors and
            public institutions.
          </p>
        </div>
      </div>

      <div className="container">
        <div className="rounded my-1 py-3 border border-light">
          <div className="row p-3">
            <div className="col-md-4 p-3 text-center">
              <div className="border-primary border-3 shadow border-top text-dark p-2 rounded">
                <div className="p-3">
                  <h4>Mechanical</h4>
                  <p className="text-muted">
                    We provide Mechanical Engineering, Construction,
                    Installation and Project Management services to oil and gas
                    fields
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-3 text-center">
              <div className="border-primary border-3 shadow border-top text-dark p-2 rounded">
                <div className="p-3">
                  <h4>Civil Works</h4>
                  <p className="text-muted">
                    Timeliness, Accuracy, and Budget are the critical watchwords
                    in the provision of Civil Engineering services.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-3 text-center">
              <div className="border-primary border-3 shadow border-top text-dark p-2 rounded">
                <div className="p-3">
                  <h4>Projects</h4>
                  <p className="text-muted">
                    We have undergone series of projects for the Dangote Group
                    and several other multinational Companies across Africa
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-md-4">
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  FUNDAMENTAL PRINCIPLES OF SAFETY 2000 LIMITED GOALS
                </Accordion.Header>
                <Accordion.Body>
                  Client satisfaction is our primary company objective. We will
                  fulfill this demanding requirement by continuously and
                  consistently improving the quality of our services. The
                  quality policy is a direct responsibility of the management.
                  In order to execute the fundamental principles of quality
                  assurance, the main procedures are laid down in the Quality
                  Manual. The quality of our services is guaranteed by the high
                  level of individual responsibility exercised by each employee.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>VALUES</Accordion.Header>
                <Accordion.Body>
                  Our values are the uncompromising foundations upon which we
                  build our mission.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>RELIABILITY</Accordion.Header>
                <Accordion.Body>
                  Reliability: the ability to deliver on what we promise,
                  without exception.{" "}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-md-4">
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>ACCOUNTABILITY</Accordion.Header>
                <Accordion.Body>
                  Accountability: the strength to be responsible for our own
                  actions and decisions.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>OUR MISSION</Accordion.Header>
                <Accordion.Body>
                  Our mission is to satisfy our customer’s requirements through
                  conformity by utilizing proven process techniques that allow
                  for the efficient execution of projects meeting the time,
                  cost, and quality requirements of our clients with a
                  commitment to continual improvement in quality. This
                  commitment to maintaining customer
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-md-4">
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>INTEGRITY</Accordion.Header>
                <Accordion.Body>
                  Integrity: the courage to maintain the right from the wrong
                  without compromise.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>OUR VISION</Accordion.Header>
                <Accordion.Body>
                  Our vision is to become the Regional leader in the
                  construction of Oil & Gas cross-country pipelines including
                  tank construction engineering works encompassing Product
                  Storage Facilities and Tank Farms for the Petrochemical, Oil
                  and Gas industries, in addition to Thermal Energy Storage
                  (TES) Tanks and Gas turbine technology for power projects.{" "}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "azure" }} className="px-3 py-5 my-3">
        <div className="container py-3">
          <div className="row">
            <div className="col-md-6 p-4">
              <h2>Who we are</h2>

              <p>
                We are simply into engineering, Electrical, Mechanical,
                construction & consulting services, marine services, procurement
                and oil & gas service. We are heavily invested in the present
                Nigerian gas revolution, and are poised to actively participate
                in piping and Gas pipeline construction network across the
                nation.
              </p>
              <p>
                Our employees work in a future oriented and quality conscious
                way to ensure effective and efficient service delivery in all
                parts of the country.
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="rounded img-fluid"
                src="../5ED7C139-A46A-47BC-8236-03EC181EACDA.jpeg"
                width="100%"
                height={isMobile ? 250 : 350}
                alt="about us"
              />
            </div>
          </div>
        </div>
      </div>

      <ProjectGallery />

      <div style={{ backgroundColor: "white" }} className="px-3 py-5 my-3">
        <div className="container py-3">
          <div className="row">
            <div className="col-md-6">
              <img
                className="rounded"
                src="../8EC2AA5F-C56C-43FE-A358-E86D4B7A8901.png"
                width="100%"
                height={isMobile ? 250 : 350}
                alt="indigenous talents"
              />
            </div>
            <div className="col-md-6 p-4">
              <h2>Indigenous Talents</h2>

              <p>
                Safety 2000 Limited is a respected consulting and engineering
                partner to industrial clients, private investors and public
                institutions. We offer an entire performance spectrum in the
                fields of project development, structural engineering,
                industrial construction, urban planning, water, transport and
                environment.
              </p>
              <p>
                Little wonder, that we are able to plan and competently execute
                projects at all stages according to required specification, thus
                giving us the National and international recognition and
                accolade that we enjoy today.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card bg-info my-3">
        <img
          style={{ objectFit: "cover" }}
          src="../BBCCDED2-614A-42B3-B145-E55798AF010D.jpeg"
          className="card-img d-none d-md-block"
          height={350}
          width="100%"
        />
        <img
          style={{ objectFit: "cover" }}
          src="../BBCCDED2-614A-42B3-B145-E55798AF010D.jpeg"
          className="card-img d-block d-md-none"
          height={600}
          width="100%"
        />
        <div className="card-img-overlay text-white p-1 my-3 justify-content-center align-content-center">
          <div className="container">
            <div className="row">
              <div className="col-md-6 p-3">
                <div className="bg-primary rounded p-4">
                  <h2 className="px-2 my-3">Contact Us</h2>
                  <h4 className="px-2 my-1">Canada: +1 647-879-5600</h4>
                  <h4 className="px-2 my-1">Nigeria: +234 8034340995</h4>
                  <h4 className="px-2">Email: info@safety2000.com</h4>
                </div>
              </div>
              <div className="col-md-6 p-3">
                <div className="bg-dark rounded">
                  <div className="p-4 text-center">
                    <NewsLetterBox />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white border-bottom my-4">
        <div className="container">
          <h2 className="py-3">Our Clients</h2>
          <div className="bg-light">
            {clientLogos &&
              clientLogos.map((item, i) => {
                return (
                  <img
                    className="img-fluid mx-1 p-3 rounded"
                    src={`${apiUrl}/${item.cpagephoto}`}
                    height={80}
                    width={100}
                    alt={item.cpagename}
                  />
                );
              })}
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="container">
          <h2 className="py-3">Latest posts from our blog</h2>

          <div className="row">
            {stories.length > 0 &&
              stories.map((item, i) => {
                return (
                  <div key={i} className="col-md-4 p-3 bg-light">
                    <Link
                      className="text-decoration-none text-dark text-left"
                      to={`/blog/post/${item.titleslug}`}
                    >
                      <div className="d-flex justify-content-center mb-2">
                        <img
                          className="rounded img-fluid"
                          src={`${apiUrl}/${item.artphoto}`}
                          width={isMobile ? "80%" : "100%"}
                          height={250}
                          alt={item.title}
                        />
                      </div>
                      <h5>{item.title}</h5>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
