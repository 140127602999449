import React, { useState, useEffect, useContext } from 'react';
import { SiteContext } from '../context/index';
import ReactModal from 'react-modal';

const ProjectGallery = () => {
    const { userData, apiUrl, isLoggedOn, categories, isMobile  } = useContext(SiteContext);

    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [photos, setPhotos] = useState([]);
    const [photoCat, setPhotoCat] = useState([]);
    const [filteredPhotos, setFilteredPhotos] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState({});
    const [selectedCat, setSelectedCat] = useState('All');
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState(''); 

    useEffect(() => {
        let isMounted = true;

        if(isMounted) {
        getAllPhotos();                
      }

      return () => {
          isMounted = false
      }
      },[])
      

      const doSelected = (cat) => {
        if(cat === 'all') {
          setFilteredPhotos(photos);  
        } else {
          let allphotos = photos.slice();
          let filteredCat = allphotos.filter((item) => item.gcatid === cat);
      
          setSelectedCat(cat);
          setFilteredPhotos(filteredCat);  
        }
      }
           
    
      
      const getAllPhotos = () => {

        return fetch(`${apiUrl}/api/photogallery.php?action=fetch`, {
          method: 'get'
        })
          .then((res) => res.json())
          .then(async (res) => {
            console.log(res);

            if(res.status === 200) {
                setMsg(res.msg);
              setTimeout(() => {
                setMsg('');
                setLoading(false);
                setPhotos(res.photos);
                setFilteredPhotos(res.photos);
                setPhotoCat(res.categories);
                }, 2000);
              } 
              else if(res.status === 400) {
              setErrorMsg(res.msg);
              setTimeout(() => {
                setErrorMsg('');
              }, 5000);
              setLoading(false);
            } else {
              alert(
                    'Operation failed',
                    'Error connection to the server please check your details and try again',
                    [{ text: 'Okay' }]
                  );
            }
          })
          .catch((error) => {
            console.log('Api call error', error.message);
          });
        }

        const handleItemSelected = (item) => {
          setItemSelected(item);
          setShowModal(true);
        }

    return (
        <div>
      <ReactModal
      isOpen={showModal}
      ariaHideApp={false}
      style={{
        overlay: {
          flex:1,
          justifyContent: 'center',
          alignContent: 'center',
          position: 'fixed',
          top: isMobile ? '1%' : '5%',
          bottom: isMobile ? '30%' : '5%',
          left: isMobile ? 0 : '15%',
          right: isMobile ? 0 : '15%',
          width: isMobile ? '100%' : '70%',
          height: isMobile ? 'auto' : '600px',
          backgroundColor: 'rgba(255, 255, 255, 0.75)'
        },
        content: {
          position: 'absolute',
          top: '20px',
          left: '20px',
          right: '20px',
          bottom: '20px',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '20px'
        }
      }}>
        <div>
          <img className='img-fluid' src={`${apiUrl}/${itemSelected.gphoto}`} height={'100%'} width={'100%'} alt={itemSelected.gdesc} />
          <p style={{'position':'absolute', 'right':'10px', 'top': '10px', 'padding':'10px', 'backgroundColor':'#333', 'color':'#fff'}} className='btn btn-secondary' onClick={() => setShowModal(false)}>X</p>
          <p style={{'position':'absolute', 'bottom':'10px', 'padding':'10px', 'backgroundColor':'#333', 'color':'#fff'}} className='my-2 py-2'>{itemSelected.gdesc}</p>
          
        </div>
      </ReactModal>


            <div className='bg-light'>
            <div className='container'>
                <h2 className='py-3'>Some of our projects</h2>
                {photoCat && 
                <div className='row'>
                    <button className='col m-2 p-2' onClick={() => doSelected('all')}>All</button>
                    {photoCat.map((item,i) => {
                        return <button key={i} className='col m-2 p-2' onClick={() => doSelected(item.gallcatid)}>{item.gallcatname}</button>
                    })}
                </div>
              }
              
                <div className='row'>
                    {filteredPhotos && filteredPhotos.map((item,i) => {
                        return <div key={i} className='col-md-3 col-6 my-2 p-2'>
                                <button onClick={() => handleItemSelected(item)}><img src={`${apiUrl}/${item.gphoto}`} alt={item.gdesc} height={200} width={'100%'} /></button>
                            </div>
                    })}
                </div>
            </div>
            </div>

        </div>
    )
}

export default ProjectGallery;