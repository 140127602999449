import React, {useContext} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { 
    Home,
    Login, 
    Signup, 
    SignupDriver, 
    SignupCompany,
    Profile, 
    AdminDashboard, 
    DriverDashboard, 
    Register,
    Account,
    Users,
    Drivers,
    AdminProducts,
    UserDashboard,
    UserProfileDisplay,
    FleetOwners,
    UpdatePassword,
    Products,
    Transactions,
    TransactionsUser,
    DriveRequests,
    DriveRequestsDrivers,
    TransactionDetail,
    Contact,
    AboutUs,
    Articles,
    ArticleAdd,
    ArticleUpdate,
    Blogs,
    Blog,
    BlogLists,
    AdminProductsCat,
    ProductsCategory,
    ProductsList,
    SitePages,
    SitePageAdd,
    SitePageUpdate,
    Faqs,
    FaqAdd,
    FaqUpdate,
    SiteConfigUpdate,
    SiteConfig,
    AdminFaqs,
    FaqsCategory,
    FaqsList,
    PageDetail,
    AdminNewsletters,
    NewsletterAdd,
    NewsletterUpdate,
    NothingMatch,
    AdminJobCat,
    Activation,
    ActivationCompany,
    SignupStep2,
    SignupComplete,
    SignupCompanyStep2,
    SignupCompanyComplete,
    JobCategory,
    JobCategoryList,
    JobCategoryDetail,
    Employers,
    CompanyProfileDisplay,
    Projects,
    AdminPhotoGallery,
    PhotoGalleryAdd,
    PhotoGalleryUpdate,
    AdminPhotoGalleryCat,
    PhotoGalleryCatAdd,
    PhotoGalleryCatUpdate,
    Service,
    ServiceLists,
    Services,
    HsePolicy
 } from '../screens';
 import { ProductDetail, OrderComplete, Checkout, OrderForm } from '../components';
import { SiteContext } from '../context';

const SiteRoutes = () => {

    const { isLoggedOn  } = useContext(SiteContext);

    const loggedin = localStorage.getItem('loggedin');

    const userloggedOn = loggedin || isLoggedOn;
    
    const RequireAuth = ({children}) => {
        
        if(!userloggedOn) {
            return <Navigate to='/login' />
        }

        return children
    }

    const MainNav = () => {
        return (
            <Routes>
                <Route path='/' element={<Home/>} />
                <Route path='/login' element={<Login/>} />
                <Route path='/signup' element={<Signup/>} /> 
                <Route path='/signup/driver' element={<SignupDriver/>} /> 
                <Route path='/register' element={<Register />} />
                <Route path='/products' element={<Products/>} />
            </Routes>
        )
    }


    const AuthNav = () => {
        return (
            <Routes> 
                <Route path='/' element={<Home/>} />
                <Route path='/login' element={<Login />} />
                <Route path='/signup' element={<Signup />} /> 
                <Route path='/signup-step2' element={<SignupStep2 />} /> 
                <Route path='/signup-complete' element={<SignupComplete />} /> 
                <Route path='/signup/company' element={<SignupCompany />} /> 
                <Route path='/signup-company-step2' element={<SignupCompanyStep2 />} /> 
                <Route path='/signup-company-complete' element={<SignupCompanyComplete />} />                 
                <Route path='/register' element={<Register />} />
                <Route path='/activation/:rkey/:email' element={<Activation />} />
                <Route path='/activation-company/:rkey/:email' element={<ActivationCompany />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/about' element={<AboutUs />} />
                <Route path='/products' element={<Products />}>
                    <Route path='' element={<ProductsList />} />
                    <Route path='category/:slug' element={<ProductsCategory />} /> 
                    <Route path='detail/:slug' element={<ProductDetail/>} />                    
                </Route> 
                <Route path='/categories' element={<JobCategoryList />}>
                    <Route path='' element={<JobCategory />} />
                    <Route path='category/:slug' element={<JobCategoryDetail />} />
                </Route>
                <Route path='/addorder' element={<RequireAuth><OrderForm /></RequireAuth>} />
                <Route path='/transactions' element={<RequireAuth><Transactions /></RequireAuth>} />
                <Route path='/mytransactions' element={<RequireAuth><TransactionsUser /></RequireAuth>} />
                <Route path='/order/:orderref' element={<RequireAuth><TransactionDetail/></RequireAuth>} />
                <Route path='/ordercomplete/:ref' element={<RequireAuth><OrderComplete /></RequireAuth>} />
                <Route path='/checkout' element={<RequireAuth><Checkout /></RequireAuth>} />
                <Route path='/users' element={<RequireAuth><Users /></RequireAuth>} />
                <Route path='/addarticle' element={<RequireAuth><ArticleAdd /></RequireAuth>} />
                <Route path='/updatearticle' element={<RequireAuth><ArticleUpdate /></RequireAuth>} />
                <Route path='/articles' element={<RequireAuth><Articles /></RequireAuth>} />
                <Route path='/addpage' element={<RequireAuth><SitePageAdd /></RequireAuth>} />
                <Route path='/updatepage' element={<RequireAuth><SitePageUpdate /></RequireAuth>} />
                <Route path='/adminpages' element={<RequireAuth><SitePages /></RequireAuth>} />
                <Route path='/addfaq' element={<RequireAuth><FaqAdd /></RequireAuth>} />
                <Route path='/updatefaq' element={<RequireAuth><FaqUpdate /></RequireAuth>} />
                <Route path='/adminfaqs' element={<RequireAuth><AdminFaqs /></RequireAuth>} />
                <Route path='/addphotogallery' element={<RequireAuth><PhotoGalleryAdd /></RequireAuth>} />
                <Route path='/updatephotogallery' element={<RequireAuth><PhotoGalleryUpdate /></RequireAuth>} />
                <Route path='/adminphotogallery' element={<RequireAuth><AdminPhotoGallery /></RequireAuth>} />
                <Route path='/addphotogallerycat' element={<RequireAuth><PhotoGalleryCatAdd /></RequireAuth>} />
                <Route path='/updatephotogallerycat' element={<RequireAuth><PhotoGalleryCatUpdate /></RequireAuth>} />
                <Route path='/adminphotogallerycat' element={<RequireAuth><AdminPhotoGalleryCat /></RequireAuth>} />
                <Route path='/addnewsletter' element={<RequireAuth><NewsletterAdd /></RequireAuth>} />
                <Route path='/updatenewsletter' element={<RequireAuth><NewsletterUpdate /></RequireAuth>} />
                <Route path='/adminnewsletters' element={<RequireAuth><AdminNewsletters /></RequireAuth>} />
                <Route path='/adminsiteupdate' element={<RequireAuth><SiteConfigUpdate /></RequireAuth>} />
                <Route path='/adminsiteconfig' element={<RequireAuth><SiteConfig /></RequireAuth>} />
                <Route path='/employers' element={<RequireAuth><Employers /></RequireAuth>} />
                <Route path='/drivers' element={<RequireAuth><Drivers /></RequireAuth>} />
                <Route path='/driverrequests' element={<RequireAuth><DriveRequests/></RequireAuth>} />
                <Route path='/driverrequests/pending' element={<RequireAuth><DriveRequestsDrivers/></RequireAuth>} />
                <Route path='/fleetowners' element={<RequireAuth><FleetOwners/></RequireAuth>} />
                <Route path='/adminproducts' element={<RequireAuth><AdminProducts/></RequireAuth>} />
                <Route path='/adminproductscat' element={<RequireAuth><AdminProductsCat/></RequireAuth>} />
                <Route path='/adminjobcat' element={<RequireAuth><AdminJobCat/></RequireAuth>} />
                <Route path='/hse-policy' element={<HsePolicy/>} />
                <Route path='/projects' element={<Projects/>} />
                <Route path='/page/:id' element={<PageDetail/>} />
                <Route path='/blog' element={<BlogLists/>}>
                    <Route path='' element={<Blogs/>} />
                    <Route path='post/:id' element={<Blog />} />
                </Route>
                <Route path='/our-services' element={<ServiceLists/>}>
                    <Route path='' element={<Services/>} />
                    <Route path='page/:id' element={<Service />} />
                </Route>
                <Route path='/faqs' element={<FaqsList/>}>
                    <Route path='' element={<Faqs/>} />
                    <Route path='category/:slug' element={<FaqsCategory/>} />
                </Route>
                <Route path='/profile' element={<RequireAuth><Profile /></RequireAuth>} />
                <Route path='/changepassword' element={<RequireAuth><UpdatePassword /></RequireAuth>} />
                <Route path='/userprofile' element={<RequireAuth><UserProfileDisplay /></RequireAuth>} />
                <Route path='/companyprofile' element={<RequireAuth><CompanyProfileDisplay /></RequireAuth>} />
                <Route path='/account' element={<RequireAuth><Account /></RequireAuth>} />
                <Route path='/admin' element={<RequireAuth><AdminDashboard /></RequireAuth>} />
                <Route path='/user' element={<RequireAuth><UserDashboard /></RequireAuth>} />
                <Route path='/driver' element={<RequireAuth><DriverDashboard /></RequireAuth>} />
                <Route path='*' element={<NothingMatch />} />
            </Routes>
        )
    }

    return (
        <Router>
            <AuthNav />
        </Router>
    )
}

export default SiteRoutes;