import React, {useState, useEffect, useContext} from 'react';
import { NavigationBar } from '../components';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { SiteContext } from '../context';

const Service = () => {

  let {id} = useParams();

const { apiUrl } = useContext(SiteContext);

const [content, setContent] = useState({});

useEffect(() => {
    doViewsUpdate();
    window.scrollTo(0,0);
},[id])

const doViewsUpdate = () => {
    fetch(`${apiUrl}/api/pages.php?action=fetchsingleservice&id=${id}`, {
        method:'get'
      }).then((res) => {
          return res.json()
      }).then((res) => {
        if(res.status === 200) {
            setContent(res.page);
          } else if(res.status === 400) {
            console.log(res.msg)
          }
      }).catch((err) => {
          console.log(err)
      })    
}
    return (
        <div>
            <div className='container my-2 p-3'>
                    <NavigationBar section={'Services'} page={content.cpagename} goback={true} />


              <div className='p-2'>
              {content && content.hasOwnProperty('cpageid') ? <div className='my-1 py-2 rounded'>
                  <h3 className='py-2'>{content.cpagename}</h3>

              <img className='col-md-6' src={`${apiUrl}/${content.cpagephoto}`} height={250} width='90%' alt={content.cpagename} /><br />
            
              <div className='py-2' dangerouslySetInnerHTML={{__html: content.cpagecontent }} />

            </div>: <div className='my-2'>Content {id} not found</div>}
            </div>

            </div>
 
        </div>
    )
}

export default Service;