import React, {useState, useEffect, useContext} from 'react';
import {Header, Footer} from './';
import { Pagination } from '../components';
import moment from 'moment';
import { Link, useNavigate, useLocation, Outlet } from 'react-router-dom';
import { SiteContext } from '../context';

const ServiceLists = () => {

const { apiUrl } = useContext(SiteContext);

const [pageServices, setPageServices] = useState([]);
const [total, setTotal] = useState(null);
const [limit, setLimit] = useState(5);
const [page, setPage] = useState(1);
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

useEffect(() => {
  getPageServices();
},[])

const getPageServices = () => {
  fetch(`${apiUrl}/api/pages.php?action=fetchpageservices`, {
    method:'get'
  }).then((res) => {
      return res.json()
  }).then((res) => {
    if(res.status === 200) {
      if(res.rows.length > 0) {
        setPageServices(res.rows);    
    }
      } else if (res.status === 400) {
          setMsg(res.msg)
          setTimeout(() => {
            setMsg('')
          },3000)
      }
  }).catch((err) => {
      console.log(err)
  })
}

    return (
        <div>
            <Header />
            <div className='container my-1 p-1'>

                <div className='row'>
                    <div className='col-md-8'>
                        <Outlet />
                    </div>
                    <div className='col-md-4 bg-light mt-2'>
                        <h4 className='bg-dark text-white rounded border-bottom p-2'>Services</h4>
                        {pageServices.length > 0 && pageServices.map((page,i) => {
                            return (
                            <div key={i} className='text-decoration-none border-bottom p-3 my-2'>
                              <Link className='text-primary text-decoration-none' to={`/our-services/page/${page.cpageid}`} >
                                  <div className='row'>
                                  <div className='col-3'><img className='img-fluid align-self-center mx-2 pr-1' src={`${apiUrl}/${page.cpagephoto}`} height={70} width={'100%'} alt={page.cpagename} /></div>
                                <div className='col-9'>{page.cpagename}</div>                                      
                                  </div>
                              </Link>
                            </div>  
                            )
                         })}

                    </div>
                </div>
            </div>
          <Footer />
        </div>
    )
}

export default ServiceLists;