import React, {useState, useEffect, useContext} from 'react';
import { Pagination, NavigationBar } from '../components';
import moment from 'moment';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { SiteContext } from '../context';

const Services = () => {

  let navigate = useNavigate();

const { apiUrl, userData } = useContext(SiteContext);

const [contents, setContents] = useState([]);
const [total, setTotal] = useState(null);
const [limit, setLimit] = useState(10);
const [page, setPage] = useState(1);
const [cangoBack, setCangoBack] = useState(true);
const [cangoForward, setCangoForward] = useState(true);
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

useEffect(() => {
  getContents();
},[])

const getContents = () => {
  fetch(`${apiUrl}/api/pages.php?action=fetchpageservices`, {
    method:'get'
  }).then((res) => {
      return res.json()
  }).then((res) => {

    if(res.status === 200) {
      if(res.rows.length > 0) {
        setContents(res.rows);
    }
      } else if (res.status === 400) {
          setMsg(res.msg)
          setTimeout(() => {
            setMsg('')
          },3000)
      }
  }).catch((err) => {
      console.log(err)
  })
}

    return (
        <div>
                <div className='my-2'>
                    <NavigationBar section={'Services'} goback={false} />

                {contents.length > 0 ? 
                    <div className='row'>
                        {contents.map((content,i) => {
            return (
                <div className='p-4 text-left'>
              <Link className='text-decoration-none text-dark text-left' to={`page/${content.cpageid}`}>
                  <div className='row my-1 py-2 border rounded'>
                      <div className='col-4'>
                      <img src={`${apiUrl}/${content.cpagephoto}`} height={200} width={'100%'} alt={content.cpagename} />
                      </div>
                      <div className='col-8 p-3'>
                      <h4>{content.cpagename}</h4>
                      <div className='py-2' dangerouslySetInnerHTML={{__html: content.cpagecontent.split(' ',20).join(' ') }} />
                      </div>
                  </div></Link>
                </div>)
              })}

          </div>
       : <div className='my-2'>No content found</div>}

              </div>
        </div>
    )
}

export default Services;