import React, {useState, useEffect, useContext} from 'react';
import {Header, Footer} from './';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { DashboardMenu, NavigationBar } from '../components';
import { SiteContext } from '../context';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    sitename: Yup.string()
      .label('Site name')
      .required()
      .min(3, 'Must have at least 3 characters'),
    sitedescription: Yup.string()
    .label('Site description')
    .required()
    .min(5, 'Must have at least 5 characters'),
    sitephone: Yup.string()
    .label('Phone')
    .required()
    .min(8, 'Must have at least 8 characters'),
    siteemailaddress: Yup.string()
    .label('Email address')
    .required(),
    siteemailname: Yup.string()
    .label('Email address sender name')
    .required(),
    siteorderemail: Yup.string()
    .label('Order email address')
    .required(),
    siteaddress: Yup.string()
    .label('Address')
    .required(),
    sitestate: Yup.string()
    .label('State')
    .required(),
    sitecountry: Yup.string()
    .label('Country')
    .required(),
    sitemetakey: Yup.string()
    .label('Meta key')
    .required(),
    sitemetadesc: Yup.string()
    .label('Meta description')
    .required(),
  });

const SiteConfigUpdate = () => {

    let location = useLocation(); 
    let navigate = useNavigate();

const { apiUrl } = useContext(SiteContext);

const userData = JSON.parse(localStorage.getItem('user'));

const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');


  const handleSubmit = (values, { setSubmitting, resetForm }) => {

    let formData = new FormData();
  
    formData.append('siteid', values.siteid);
    formData.append('sitename', values.sitename);
    formData.append('sitedescription', values.sitedescription);
    formData.append('sitephone', values.sitephone);
    formData.append('siteemailname', values.siteemailname);
    formData.append('siteemailaddress', values.siteemailaddress);
    formData.append('siteorderemail', values.siteorderemail);
    formData.append('sitemetakey', values.sitemetakey);
    formData.append('sitemetadesc', values.sitemetadesc);
    formData.append('siteaddress', values.siteaddress);
    formData.append('sitestate', values.sitestate);
    formData.append('sitecountry', values.sitecountry);
    formData.append('sitefacebook', values.sitefacebook);
    formData.append('sitetwitter', values.sitetwitter);
    formData.append('siteinstagram', values.siteinstagram);
    formData.append('siteyoutube', values.siteyoutube);
    formData.append('photo', values.photo);
    formData.append('updatedby', values.updatedby);
    formData.append('role', userData.role);
    formData.append('action', 'update');
  
      return fetch(`${apiUrl}/api/siteupdate.php`, {
        method: 'post',
        body: formData
      })
          .then((res) => res.json())
        .then(async (res) => {
  
          console.log(res);
  
          if(res.status === 200) {
              setSubmitting(false);
              setMsg(res.msg);
              setTimeout(() => {
                setMsg('');
              }, 3000);
              setTimeout(() => {
                navigate('/adminsiteconfig');
              }, 3000);
          } else if(res.status === 400) {
            setErrorMsg(res.msg);
            setTimeout(() => {
              setErrorMsg('');
            }, 5000);
            setSubmitting(false);
          } else {
            alert(
                  'Site Config update failed',
                  'Error connection to the server please check your details and try again',
                  [{ text: 'Okay' }]
                );
          }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    };


    return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>

                    <NavigationBar section={'Site Config'} goback={true} />

                <h3>Site Config Update</h3>
                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                  </div>
                </div>

                <Formik
                    initialValues={{
                            siteid: location.state.sitedetail.shopid != '' ? location.state.sitedetail.shopid : '',
                            sitename: location.state.sitedetail.shopname != '' ?  location.state.sitedetail.shopname : '',
                            sitedescription: location.state.sitedetail.shopdescription != '' ? location.state.sitedetail.shopdescription : '',
                            sitephone: location.state.sitedetail.shopphone != '' ? location.state.sitedetail.shopphone : '',
                            siteemailname: location.state.sitedetail.shopemailname != '' ? location.state.sitedetail.shopemailname : '',
                            siteemailaddress: location.state.sitedetail.shopemailaddress != '' ? location.state.sitedetail.shopemailaddress : '',
                            siteorderemail: location.state.sitedetail.shoporderemail != '' ? location.state.sitedetail.shoporderemail : '',
                            sitemetakey: location.state.sitedetail.shmetakey != '' ? location.state.sitedetail.shmetakey : '',
                            sitemetadesc: location.state.sitedetail.shmetadesc != '' ? location.state.sitedetail.shmetadesc : '',
                            siteaddress: location.state.sitedetail.shopaddress != '' ? location.state.sitedetail.shopaddress : '',
                            sitestate: location.state.sitedetail.shopstate != '' ? location.state.sitedetail.shopstate : '',
                            sitecountry: location.state.sitedetail.shopcountry != '' ? location.state.sitedetail.shopcountry : '',
                            sitefacebook: location.state.sitedetail.slfacebook != '' ? location.state.sitedetail.slfacebook : '',
                            sitetwitter: location.state.sitedetail.sltwitter != '' ? location.state.sitedetail.sltwitter : '',
                            siteinstagram: location.state.sitedetail.slinstagram != '' ? location.state.sitedetail.slinstagram : '',
                            siteyoutube: location.state.sitedetail.slyoutube != '' ? location.state.sitedetail.slyoutube : '',
                            photo: location.state.sitedetail.shoplogo != '' ? location.state.sitedetail.shoplogo : '',
                            updatedby: location.state.sitedetail.postedby != '' ? location.state.sitedetail.postedby : userData.username,
                        }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='row my-2'>
                          <div className='col-md-7'>

                          <div className='form-group my-1'>
                          <div>
                            <label htmlFor="sitename">Site name</label>
                          </div>
                            <Field
                              id="sitename"
                              name="sitename"
                              value={values.sitename}
                              onBlur={handleBlur('sitename')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />

                          {touched.sitename && errors.sitename && <div className='py-1'>
                                <span className='text-danger'>{touched.sitename && errors.sitename}</span>
                          </div>}
                        </div>

                            <div className='form-group my-2'>
                            <div>
                              <label htmlFor="sitedescription">Description</label>
                            </div>
                            <Field
                              as="textarea"
                              id="sitedescription"
                              name="sitedescription"
                              value={values.sitedescription}
                              onBlur={handleBlur('sitedescription')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.sitedescription && errors.sitedescription && <div className='py-1'>
                                  <span className='text-danger'>{touched.sitedescription && errors.sitedescription}</span>
                            </div>}
                          </div>


                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="sitephone">Phone</label>
                          </div>
                            <Field
                              id="sitephone"
                              name="sitephone"
                              value={values.sitephone}
                              onBlur={handleBlur('sitephone')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.sitephone && errors.sitephone && <div className='py-1'>
                                <span className='text-danger'>{touched.sitephone && errors.sitephone}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="siteemailname">Email address name</label>
                          </div>
                            <Field
                              id="siteemailname"
                              name="siteemailname"
                              value={values.siteemailname}
                              onBlur={handleBlur('siteemailname')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.siteemailname && errors.siteemailname && <div className='py-1'>
                                <span className='text-danger'>{touched.siteemailname && errors.siteemailname}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="siteemailaddress">Email address</label>
                          </div>
                            <Field
                              id="siteemailaddress"
                              name="siteemailaddress"
                              value={values.siteemailaddress}
                              onBlur={handleBlur('siteemailaddress')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.siteemailaddress && errors.siteemailaddress && <div className='py-1'>
                                <span className='text-danger'>{touched.siteemailaddress && errors.siteemailaddress}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="siteorderemail">Order email address</label>
                          </div>
                            <Field
                              id="siteorderemail"
                              name="siteorderemail"
                              value={values.siteorderemail}
                              onBlur={handleBlur('siteorderemail')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.siteorderemail && errors.siteorderemail && <div className='py-1'>
                                <span className='text-danger'>{touched.siteorderemail && errors.siteorderemail}</span>
                          </div>}
                        </div>

                        <div className='form-group my-2'>
                            <div>
                              <label htmlFor="siteaddress">Address</label>
                            </div>
                            <Field
                              as="textarea"
                              id="siteaddress"
                              name="siteaddress"
                              value={values.siteaddress}
                              onBlur={handleBlur('siteaddress')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.siteaddress && errors.siteaddress && <div className='py-1'>
                                  <span className='text-danger'>{touched.siteaddress && errors.siteaddress}</span>
                            </div>}
                          </div>

                          <div className='form-group my-2'>
                            <div>
                              <label htmlFor="sitestate">State</label>
                            </div>
                            <Field
                              id="sitestate"
                              name="sitestate"
                              value={values.sitestate}
                              onBlur={handleBlur('sitestate')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.sitestate && errors.sitestate && <div className='py-1'>
                                  <span className='text-danger'>{touched.sitestate && errors.sitestate}</span>
                            </div>}
                          </div>

                          <div className='form-group my-2'>
                            <div>
                              <label htmlFor="sitecountry">Country</label>
                            </div>
                            <Field
                              id="sitecountry"
                              name="sitecountry"
                              value={values.sitecountry}
                              onBlur={handleBlur('sitecountry')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.sitecountry && errors.sitecountry && <div className='py-1'>
                                  <span className='text-danger'>{touched.sitecountry && errors.sitecountry}</span>
                            </div>}
                          </div>



                          </div>
                          <div className='col-md-5'>
                          <div className='form-group my-3'>
                                <div>
                                    <label htmlFor="photo"> Upload logo</label>
                                </div>
                                <input id="photo" name="photo" type="file" onChange={(event) => {
                                        setFieldValue("photo", event.currentTarget.files[0]);
                                }} />
                                                        {location.state.sitedetail && <img className='my-2' src={`${apiUrl}/${location.state.sitedetail.shoplogo}`} height={100} width={150} alt="picture" />}
                        </div>

                        <div className='form-group my-2'>
                            <div>
                              <label htmlFor="sitemetakey">Meta keywords</label>
                            </div>
                            <Field
                              id="sitemetakey"
                              name="sitemetakey"
                              value={values.sitemetakey}
                              onBlur={handleBlur('sitemetakey')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.sitemetakey && errors.sitemetakey && <div className='py-1'>
                                  <span className='text-danger'>{touched.sitemetakey && errors.sitemetakey}</span>
                            </div>}
                          </div>

                          <div className='form-group my-2'>
                            <div>
                              <label htmlFor="sitemetadesc">Meta description</label>
                            </div>
                            <Field
                              as="textarea"
                              id="sitemetadesc"
                              name="sitemetadesc"
                              value={values.sitemetadesc}
                              onBlur={handleBlur('sitemetadesc')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.sitemetadesc && errors.sitemetadesc && <div className='py-1'>
                                  <span className='text-danger'>{touched.sitemetadesc && errors.sitemetadesc}</span>
                            </div>}
                          </div>

                          <div className='form-group my-2'>
                            <div>
                              <label htmlFor="sitefacebook">Facebook</label>
                            </div>
                            <Field
                              id="sitefacebook"
                              name="sitefacebook"
                              value={values.sitefacebook}
                              placeholder='Facebook url'
                              onBlur={handleBlur('sitefacebook')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.sitefacebook && errors.sitefacebook && <div className='py-1'>
                                  <span className='text-danger'>{touched.sitefacebook && errors.sitefacebook}</span>
                            </div>}
                          </div>

                          <div className='form-group my-2'>
                            <div>
                              <label htmlFor="sitetwitter">Twitter</label>
                            </div>
                            <Field
                              id="sitetwitter"
                              name="sitetwitter"
                              value={values.sitetwitter}
                              placeholder='Twitter url'
                              onBlur={handleBlur('sitetwitter')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.sitetwitter && errors.sitetwitter && <div className='py-1'>
                                  <span className='text-danger'>{touched.sitetwitter && errors.sitetwitter}</span>
                            </div>}
                          </div>

                          <div className='form-group my-2'>
                            <div>
                              <label htmlFor="siteinstagram">Instagram</label>
                            </div>
                            <Field
                              id="siteinstagram"
                              name="siteinstagram"
                              value={values.siteinstagram}
                              placeholder='Instagram url'
                              onBlur={handleBlur('siteinstagram')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.siteinstagram && errors.siteinstagram && <div className='py-1'>
                                  <span className='text-danger'>{touched.siteinstagram && errors.siteinstagram}</span>
                            </div>}
                          </div>

                          <div className='form-group my-2'>
                            <div>
                              <label htmlFor="siteyoutube">Youtube</label>
                            </div>
                            <Field
                              id="siteyoutube"
                              name="siteyoutube"
                              value={values.siteyoutube}
                              placeholder='Youtube url'
                              onBlur={handleBlur('siteyoutube')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.siteyoutube && errors.siteyoutube && <div className='py-1'>
                                  <span className='text-danger'>{touched.siteyoutube && errors.siteyoutube}</span>
                            </div>}
                          </div>


                          </div>
                        </div>
                        <button
                            type='submit'
                            onClick={handleSubmit}
                            title="Update"
                            disabled={!isValid || isSubmitting}
                            className='btn btn-primary'
                          >
                              Update
                          </button>
                        
                        </form>
                    )}
                  </Formik>
                              </div>
                    </div>
                </div>
            </div>
          <Footer />
        </div>
    )
}

export default SiteConfigUpdate;